<!--
 * @Author: 张阳帅
 * @Date: 2024-09-25 09:52:37
 * @LastEditTime: 2024-10-30 09:22:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\views\gjc\patient\cardList.vue
-->
<template>
  <div class="patientlist_page">
    <div class="page_main">
      <div class="main_item">
        <div class="item_info" @click="getSetDefaultPatientData(patientList)">
          <img class="info_img" src="https://lanzhoutech.oss-cn-shanghai.aliyuncs.com/naokeWebImages/h5/public_user_info.png" alt="">
          <div class="info_name">
            <div class="name_xm">
              <a v-if="patientList.sfmr=='1'" class="xm_sfmr">默认</a>
              {{ patientList.xm }} 
              <a class="xm_sjh">{{ encryptPhoneNum(patientList.sjh) }}</a>
            </div>
            <div class="name_zjh">
              {{ encryptIdCordNum(patientList.zjh) }}
            </div>
          </div>
          <!-- <div class="item_delete">
              <van-icon class="delete_edit" name="edit" size="23" color="#1e8dff" @click.stop="editPatientAction(patientList)" />
              <van-icon name="delete" size="23" color="#1e8dff" @click.stop="deletePatientAction(patientList)" />
            </div> -->
        </div>
        <!-- <div v-for="item in patientList.patientCard" class="item_card" @click="getpatientCardDetail(patientList)">
            <div class="card-left">
              <div class="card_xm" >
                {{ item.jzklxName }}
              </div>
              <div class="card_xm" style="margin-top: 2px;">
                {{ item.jzkh }}
              </div>
              <a class="xm_sfmr">默认</a>
            </div>
            
          </div> -->
      </div>
      <div class="card_yymc" style="padding: 10px 10px 0 10px;">
        <div>{{ patientList.yymc }}（{{ patientList.patientCard && patientList.patientCard.length }}张卡）</div> 
        <div class="refrsh">
          <span style="color: #1E8DFF;cursor: pointer;" @click="refrshCard(patientList.id)">刷新</span>
        </div>
      </div>
      <div v-if="patientList.patientCard && patientList.patientCard.length >0" class="item_card myCard" @click="getpatientCardDetail(patientList)">
        <div v-for="(item,index) in patientList.patientCard" :key="index" class="card-left" style="background: #E9F4FF;display: flex;padding: 15px 15px;margin: 20px 10px;border-radius: 8px;">
          <div class="card_xm" style="color: #3c4455;">
            {{ item.jzklxName }}
          </div>
          <div class="card_xm" style="margin-top: 3px; color: #3c4455;margin-left: 10px;">
            {{ item.jzkh }}
          </div>
          <a v-if="item.sfmr=='1'" class="xm_sfmr">默认</a>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  import { gjcModel } from '../../../api/gjcApi'
  import { encryptPhoneNum, encryptIdCordNum} from '@/utils'
  
  export default {
      data(){
          return{
              page:6, //页码
              patientList:{patientCard:[]} //就诊人列表
          }
      },
      mounted() {
    
          this.getPatientListData(this.$route.query.patientId)
      },
      methods:{
          getPatientListData(id){
              gjcModel.getPatientDetailData(id).then(res=>{
                  console.log('获取就诊人列表', res)
                  this.patientList = res.data
  
              })
          },
          refrshCard(id){
            gjcModel.refreshCard(id).then(res=>{
                if(res.code == 200){
                    console.log('获取就诊人列表', res)
                    this.$toast('刷新成功')
                    this.getPatientListData(id)
                }
                 
              })
          },
          //手机号码脱敏
          encryptPhoneNum(num) {
              return num ? encryptPhoneNum(num) : ''
          },
          //身份证号码脱敏
          encryptIdCordNum(num) {
              return num ? encryptIdCordNum(num) : ''
          },
          //删除就诊人
          deletePatientAction(item){
              // console.log('删除就诊人', item)
              this.$dialog.alert({
                  title: '删除提示',
                  message: '确认删除就诊人[ '+item.xm+' ]吗?',
                  showCancelButton: true
              }).then(() => {
                  this.getDeletePatientData(item.id)
              }).catch(() => {
                  this.$dialog.close()
              })
          },
          //编辑就诊人
          editPatientAction(item){
              console.log('编辑就诊人', item)
              this.$router.push({
                  path: '/patientEdit',
                  query: {
                      id: item.id
                  }
              })
          },
          //删除就诊人
          getDeletePatientData(id) {
              gjcModel.getDeletePatientData(id).then(res=>{
                  console.log('删除就诊人', res)
                  this.$toast('删除成功')
                  this.getPatientListData()
              })
          },
          //设置默认就诊人
          getSetDefaultPatientData(item) {
              gjcModel.getSetDefaultPatientData(item.id).then(res=>{
                  console.log('设置默认就诊人', res)
                  this.$router.back()
              })
          },
          //添加就诊人页面
          getPatientAddAction(){
              this.$router.push({
                  path: '/patientAdd'
              })
          },
          getpatientCardDetail(){
          }
      }
  }
  </script>
  <style scoped lang='less'>
  .patientlist_page{
      background-color: #f5f5f5;
      height: 100vh;
  }
  .card_yymc{
            font-size: 30px;
            padding-left: 30px;
            display: flex;
            justify-content: space-between;
            span{
                 color: #6A6D78;
                 font-size: 28px;
            }
          }
  .page_main{
      background-color: white;
      height: 100vh;
      overflow: auto;
      .myCard{
        margin: 24px 24px;
          // padding: 20px 24px;
          border-radius: 16px;
          padding: 8px  0 10px;
          border: 1px solid #B2B2B2;
          .xm_sfmr{
                          margin-left: 12px;
                          border-radius: 5px;
                          padding: 3px 10px;
                          font-size: 22px;
                          background: #fff;
                          color: #1E8DFF;
                          font-weight: normal;
                      }
          
      }
      .main_item{
          margin: 24px 24px;
          // padding: 20px 24px;
          background: rgba(30,141,255,0.1);
          border-radius: 24px;
          padding: 10px 0 20px 0;
          box-shadow: 0px 8px 18px 0px rgba(0,0,0,0.05);
          .item_delete{
              position: relative;
              top: 25px;
              left: 20px;
              width: 120px;
              height: 50px;
              .delete_edit{
                  margin-right: 20px;
              }
          }
          .item_info{
              display: flex;
              justify-content: flex-start;
              padding: 45px 20px;
              // background: rgba(30,141,255,0.1);
              .info_img{
                  width: 100px;
                  height: 100px;
              }
              .info_name{
                  margin-left: 20px;
                  // width: 460px;
                  .name_xm{
                      font-size: 32px;
                      color: #333333;
                      font-weight: bold;
                      .xm_sfmr{
                          margin-right: 12px;
                          border-radius: 5px;
                          padding: 3px 10px;
                          font-size: 22px;
                          color: #1e8dff;
                          font-weight: normal;
                          border: 1px solid #1e8dff;
                      }
                      .xm_sjh{
                          margin-left: 20px;
                          font-size: 32px;
                          color: #333;
                          font-weight: bold;
                      }
                  }
                  .name_zjh{
                      margin-top: 20px;
                      font-size: 28px;
                      color: #333;
                  }
              }
          }
          .item_card{
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin:  0 20px;
              padding:  20px 40px;
              background-color:#fff;
              border-radius: 12px 12px;
              .card_xm{
                  font-size: 30px !important;
                  color: #9a9a9a;
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
              .xm_sfmr{
                          margin-left: 12px;
                          border-radius: 5px;
                          padding: 3px 10px;
                          font-size: 22px;
                          background: #eee;
                          color: #9a9a9a;
                          font-weight: normal;
                      }
              .card-left{
                  display: flex;
                  .card_xm{
                  font-size: 30px;
                  color: #3c4455;
                  margin-left: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
              }
              .card-right{
                  display: flex;
                  justify-content: center;
                  align-items: center;
              }
              
          }
      }
  }
  .page_bottom{
      // position: absolute;
      // top: 0vh;
      // height: 12vh;
      width: 100%;
      background-color: white;
      .bottom_add{
          // display: flex;
          // justify-content: center;
          // justify-items: center;
          margin: 10px 50px;
          padding: 20px 50px;
          // background-color: #1e8dff;
          border-radius: 50px;
          font-size: 32px;
          color: #1e8dff;
          text-align: center;
          border:1px solid #1e8dff;
      }
  }
  
  </style>
  